@font-face {
  font-family: "Font Name";
  src: url("../fonts/BKANT.TTF");
}
@font-face {
  font-family: "Font Name ";
  src: url("../fonts/AvenirLTStd-Black.otf");
}

.footer {
  background: black;
  padding: 72px 4px 32px;
  .col1 {
    text-align: justify;
    text-justify: inter-word;
    .title {
      text-align: left;
      h1 {
        font-family: "Font Name";
        font-size: 1.5rem;
        font-weight: bolder;
        color: white;
      }
      .logoPic {
        height: 30px;
        width: 30px;
      }
    }
    .content {
      p {
        font-size: 1rem;
        font-family: "Font Name";
        color: white;
      }
    }
  }
  .col2, .col3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 4px;
      h1 {
        font-family: "Font Name";
        font-size: 1.25rem;
        font-weight: 600;
        color: white;
        margin-bottom: 12px;
      }
    }
    .content {
      text-align: left;
      ul {
        list-style-type: none;
      }
      li {
        font-size: 1rem;
        font-family: "Font Name";
        color: white;
      }
      a {
        color: white;
      }
      a:hover {
        color: blue;
      }
      ul,
      li {
        margin: 0 0 8px;
        padding: 0;
      }
      input.emailInp {
        background: transparent;
        border: 2px solid white;
        width: 200px;
        color: white;
        margin-bottom: 4px;
        &:focus-visible {
        }
      }
      ::placeholder {
        color: white;
      }
      .buttonSpace {
        text-align: "center";
        padding-top: 2%;
      }
      .subscribeBtn {
        width: 200px;
        height: 40px;
        font-size: 1rem;
        font-weight: bolder;
        color: white;
        background: transparent;
        border: 2px solid white;
        border-radius: 10px;
      }
    }
  }
}
.finalFooter {
  text-align: center;
  p {
    color: white;
    font-size: 14px;
    margin: 0;
    padding-top: 2rem;
  }
}
@media screen and (max-width: 992px) {
  .footer {
    .col2 {
      align-items: flex-start;
    }
    .col3 {
      text-align: center;
    }
  }
}
