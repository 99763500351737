.whole {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
@font-face {
  font-family: "Head Font";
  src: url("../fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: "Para Font";
  src: url("../fonts/BKANT.TTF");
}
.optionalBr{
  display: none;
}
.aboutUs {
  background: url("../images/Bg.JPG");
  .aboutSection {
    padding: 4.5rem 0;
    .title {
      text-align: center;
      font-family: "Head Font";
      h1 {
        font-size: 3.5rem;
      }
    }
    .content {
      text-align: center;
      font-family: "Para Font";
      p {
        font-size: 1.5rem;
      }
    }
  }
}
.more {
  background: black;
  color: white;
  .further {
    padding: 4.5rem 0;
    .content {
      font-family: "Para Font";
      text-align: center;
      padding: 1rem 0;
      p {
        font-size: 1.5rem;
      }
    }
  }
}
.team {
  background: url("../images/gradImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 2%;
  padding-bottom: 2%;
  .title {
    text-align: center;
    font-family: "Para Font";
    h1 {
      font-size: 3rem;
    }
  }
  .member {
    position: relative;
    .content{
      text-align: center;
      h3{
        font-size: 1.5rem;
        padding: 0;
        font-family: "Para Font";
      }
      .designation{
        padding: 0%;
        margin: 0%;
      }
      p{
        font-family: "Para Font";
        padding: 0;
      }
    }
  }
  .followBtn {
    height: 40px;
    width: 100px;
    font-size: 1rem;
    font-weight: bolder;
    background-color: black;
    color: white;
    border: none;
    border-radius: 10px;
  }
  .circleImg {
    position: absolute;
    margin: auto;
    top: -90px;
    right: 0;
    left: 0;
    .userImg {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .content {
    padding-top: 25%;
  }

  body {
    background: #f4f4f4;
  }

  .containerCard {
    margin-top: 50px;
  }
  .containerCard .team1 {
    width: 450px;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .content {
    font-weight: bolder;
    text-align: left;
    .name{
      font-weight: bolder;
      .optionTag{
        display: none;
      }
    }
    .aboutPerson {
      font-weight: bolder;
    }
  }

  .containerCard .team1 .member {
    width: 450px;
    height: 400px;
    margin: 10px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    padding: 25px;
  }

  .containerCard .team1 .member {
    width: 400px;
  }

  .containerCard .team1 .member h3 {
    margin-top: 2px;
    color: #444;
  }

  .containerCard .team1 .member span {
    font-size: 12px;
    color: #999;
  }

  .containerCard .team1 .member p {
    margin: 15px 0px;
    font-weight: 400;
    color: #999;
    font-size: 15px;
    text-align: center;
  }
  .containerCard .team1 .member .btn {
    text-align: center;
  }
  .containerCard .team1 .member .btn a {
    background: #ddd;
    display: block;
    float: right;
    width: 125px;
    margin: 0 10px;
    padding: 10px;
    border-radius: 6px;
    color: #444;
    text-transform: capitalize;
    transition: all 0.3s ease;
  }

  .containerCard .team1 .member .btn a:hover {
    background: #5a36dd;
    color: #fff;
  }
}
.ready {
  background: black;
  .query {
    padding: 4.5rem 0;
    .para {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .title {
        font-family: "Para Font";
        text-align: center;
        h1 {
          color: white;
          font-size: 3rem;
          font-weight: bolder;
          margin-bottom: 2rem;
          @media (max-width: 992px) {
            font-size: 2.5rem;
          }
          @media (max-width: 768px) {
            font-size: 2rem;
          }
        }
      }
      .content {
        max-width: 35rem;
        margin: auto;
        font-family: "Para Font";
        text-align: center;
        color: white;
        .questions {
          font-size: 1.5rem;
          font-weight: bolder;
        }
        p {
          font-size: 1.25rem;
          margin-bottom: 2rem;
        }
      }
    }
    .form {
      color: white;
      max-width: 25rem;
      margin: auto;
      label {
        color: white;
        font-weight: bold;
      }
      .btnSpace {
        text-align: center;
        .submit {
          height: 40px;
          border: 2px solid white;
          background: transparent;
          color: white;
          font-size: 1.2rem;
          font-weight: bolder;
        }
      }
    }
  }
}
.subscribe {
  padding: 4.5rem 0;
  .loop {
    padding: 1.5rem 0;
    .title {
      font-family: "Para Font";
      text-align: center;
      h1 {
        font-size: 3rem;
        font-weight: bolder;
        @media (max-width: 992px) {
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .content {
      text-align: center;

      p {
        padding: 0;
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
      }
      a {
        text-decoration: underline;
      }
      .inputs {
        padding-top: 1%;
        padding-bottom: 1%;
      }
      input {
        border: 2px solid black;
        width: 300px;
        height: 40px;
        font-size: 1.2rem;
        font-weight: bolder;
        border-radius: 0;
      }
      .submit {
        width: 120px;
        height: 39.5px;
        border: 2px solid black;
        background: transparent;
        color: black;
        font-size: 1.2rem;
        font-weight: bolder;
      }
      .email-form {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @media (max-width: 577px) {
          flex-direction: column;
        }
        & > div {
          padding: 0.5rem 1rem;
        }

        input[type="text"] {
          display: flex;
          text-align: left;
          width: 350px;
          height: 50px;
          border: 2px solid #8f94a1;
          background: transparent;
          font-size: 18px;
          padding-left: 24px;
        }

        .email-button {
          button {
            height: 50px;
            width: 150px;
            border-radius: 10px;
            background: black;
            border: none;
            color: #ffffff;
            font-size: 16px;
          }

          .ripple {
            background-position: center;
            transition: background 1s;

            &:hover {
              background: #7e6ee1
                radial-gradient(circle, transparent 1%, #3a3875 1%)
                center/15000%;
            }

            &:active {
              background-color: #3a3875;
              background-size: 100%;
              transition: background 0s;
            }

            &:focus {
              outline: none !important;
            }
          }
        }
      }
    }
  }
}
.tryOut1 {
  padding: 2rem 0;
  background: #f6f8fa;
  .demoTry {
    padding: 3.5rem 0;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 3rem;
        font-weight: bolder;
        @media (max-width: 992px) {
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .content {
      font-family: "Para Font";
      text-align: center;
      .submit {
        width: 150px;
        height: 40px;
        border: 2px solid black;
        background: transparent;
        color: black;
        font-size: 1.25rem;
        font-weight: bolder;
      }
      .demo {
        background-color: black;
        color: white;
        width: 250px;
        font-size: 1rem;
        font-weight: bolder;
        height: 50px;
        border: none;
        border-radius: 10px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  body {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
  .team {
    padding-left: 0%;
    padding-right: 0%;
    background-color: #f4f4f4;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 1.75rem;
      }
    }
    .member {
      position: relative;
      text-align: center;
    }
    
    .followBtn {
      height: 40px;
      width: 100px;
      font-size: 1rem;
      font-weight: bolder;
      background-color: black;
      color: white;
      border: none;
      border-radius: 10px;
    }
    .circleImg {
      position: absolute;
      margin: auto;
      top: -70px;
      right: 0;
      left: 0;
      .userImg {
        width: 150px;
        height: 150px;
      }
    }
    .content {
      padding-top: 20%;
    }

    body {
      background: #f4f4f4;
    }

    .containerCard {
      margin-top: 30px;
    }
    .containerCard .team1 {
      width: auto;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
    }
    .content {
      font-weight: bolder;
      .name {
        font-weight: bolder;
      }
      .aboutPerson {
        font-weight: bolder;
      }
    }

    .containerCard .team1 .member {
      width: auto;
      margin: 10px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
      padding: 25px;
    }

    .containerCard .team1 .member {
      width: 400px;
    }

    .containerCard .team1 .member h3 {
      color: #444;
    }

    .containerCard .team1 .member span {
      font-size: 12px;
      color: #999;
    }

    .containerCard .team1 .member p {
      margin: 15px 0;
      font-weight: 400;
      color: #999;
      font-size: 15px;
      text-align: center;
    }
    .containerCard .team1 .member .btn {
      text-align: center;
    }
    .containerCard .team1 .member .btn a {
      background: #ddd;
      display: block;
      float: right;
      width: 125px;
      margin: 0 10px;
      padding: 10px;
      border-radius: 6px;
      color: #444;
      text-transform: capitalize;
      transition: all 0.3s ease;
    }

    .containerCard .team1 .member .btn a:hover {
      background: #5a36dd;
      color: #fff;
    }
  }

  .aboutUs {
    background-color: linear-gradient(
      to right top,
      #f1fcfe,
      #f5fbff,
      #faf9ff,
      #fdf8fb,
      #fef8f8
    );
    .aboutSection {
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      .title {
        text-align: center;
        font-family: "Head Font";
        h1 {
          font-size: 2rem;
        }
      }
      .content {
        padding-left: 0%;
        padding-right: 0%;
        .content1 {
          text-align: justify;
          text-justify: inter-word;
        }
        .content2 {
          text-align: center;
        }
        font-family: "Para Font";
        p {
          font-size: 1.125rem;
        }
      }
    }
  }
  .more {
    background: black;
    color: white;
    .further {
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      .content {
        font-family: "Para Font";
        text-align: center;
        p {
          font-size: 1.125rem;
        }
      }
    }
  }
  .ready {
    background: black;
    .container{
      .query{
        .para{
          padding-left: 12px;
          padding-right: 12px;
          .title{
            h1{
              font-size: 1.75rem;
            }
          }
          .content{
            p{
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
  .subscribe{
    padding: 2rem 0rem;
    .loop{
      .title{
        h1{
          font-size: 1.75rem;
        }
      }
      .content{
        input{
          width: 100px;
        }
        .email-form{
          input[type="text"] {
            display: flex;
            text-align: left;
            width: 250px;
            height: 50px;
            border: 2px solid #8f94a1;
            background: transparent;
            font-size: 18px;
            padding-left: 24px;
          }
        }
      }
    }
  }

  .tryOut1{
    padding: 1rem;
    .container{
      .demoTry{
        .title{
          h1{
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .optionalBr{
    display: block;
  }
  body {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
  .team .containerCard .team1 .member {
    width: 430px;
    height: auto;
}
  .team {
    padding-left: 0%;
    padding-right: 0%;
    background-color: #f4f4f4;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 1.75rem;
      }
    }
    .people{
      padding-left: 0%;
      padding-right: 0%;
    }
    .member {
      position: relative;
    }
    .followBtn {
      height: 40px;
      width: 100px;
      font-size: 1rem;
      font-weight: bolder;
      background-color: black;
      color: white;
      border: none;
      border-radius: 10px;
    }
    .circleImg {
      position: absolute;
      margin: auto;
      top: -50px;
      right: 0;
      left: 0;
      .userImg {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }
    }
    .content {
      padding-top: 20%;
    }

    body {
      background: #f4f4f4;
    }

    .containerCard {
      margin-top: 50px;
      width: auto;
    }
    .containerCard .team1 {
      width: auto;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
    }
    .content {
      font-weight: bolder;
      .name {
        font-weight: bolder;
        width: 100%;
        .optionTag{
          display: block;
        }
      }
      
      .extra{
        padding-left: 12%;
        padding-right: 12%;
        .name1{
          font-weight: bolder;
          
        }
      }
      .aboutPerson {
        font-weight: bolder;
      }
    }

    .containerCard .team1 .member {
      width: 405px;
      margin: 10px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
      padding: 25px;
    }

    .containerCard .team1 .member {
      width: 400px;
    }

    .containerCard .team1 .member h3 {
      color: #444;
    }

    .containerCard .team1 .member span {
      font-size: 12px;
      color: #999;
    }

    .containerCard .team1 .member p {
      margin: 15px 0;
      font-weight: 400;
      color: #999;
      font-size: 15px;
      text-align: center;
    }
    .containerCard .team1 .member .btn {
      text-align: center;
    }
    .containerCard .team1 .member .btn a {
      background: #ddd;
      display: block;
      float: right;
      width: 125px;
      margin: 0 10px;
      padding: 10px;
      border-radius: 6px;
      color: #444;
      text-transform: capitalize;
      transition: all 0.3s ease;
    }

    .containerCard .team1 .member .btn a:hover {
      background: #5a36dd;
      color: #fff;
    }
  }

  .aboutUs {
    background-color: linear-gradient(
      to right top,
      #f1fcfe,
      #f5fbff,
      #faf9ff,
      #fdf8fb,
      #fef8f8
    );
    .aboutSection {
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      .title {
        text-align: center;
        font-family: "Head Font";
        h1 {
          font-size: 2rem;
        }
      }
      .content {
        padding-left: 0%;
        padding-right: 0%;
        .content1 {
          p{
            font-size: 1.125rem;
          }
        }
        .content2 {
          text-align: center;
          p{
            font-size: 1.125rem;
          }
        }
        font-family: "Para Font";
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .more {
    background: black;
    color: white;
    .further {
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      .content {
        font-family: "Para Font";
        text-align: center;
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .ready {
    .container{
      .query{
        padding: 2rem 0rem;
        .para{
          margin: 0;
          position: relative;
          top: 0%;
          -ms-transform: translateY(0%);
          transform: translateY(0%);
          .title{
            h1{
              font-size: 1.75rem;
            }
          }
        }
      }
    }
  }
  .subscribe{
    .loop{
      .title{
        h1{
          font-size: 1.75rem;
        }
      }
      .content{
        text-align: center;
        .email-form{
          
          input[type="text"]
          {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
  .tryOut1{
    .container{
      .demoTry{
        .title{
          h1{
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}
