@font-face {
    font-family: "Medium Font";
    src: url('../fonts/SourceSerifPro-Black.otf');
  }
  h4{
    font-size: 1.35rem;
    font-weight: bolder;
    font-family: "Medium Font";
  }
  a:link, a:visited {
      color: #444;
    }
    
    header {
      text-align: center;
    }
    
    body{
      background:white;
    }
    article {
      .topHeading{
        text-align: center;
        padding-bottom: 5%;
      }
      max-width: 600pt;
      margin: 0 auto;
      text-align: justify;
      text-justify: inter-word;
      ul{
          list-style-type: square;
          
          font-family: "Medium Font";
      }
      h1 {
        font-size: 2.7rem;
        font-weight: bolder;
        font-family: "Medium Font";
      }
      h3{
        font-size: 1.7rem;
        font-weight: bolder;
        font-family: "Medium Font";
      }
    }
    article p {
      font-size: 16pt;
      line-height: 22pt;
    }
    
    .article-divider {
      border: none;
      text-align: center;
    }
    .article-divider::after {
      content: "・・・";
      letter-spacing: 7pt;
      opacity: 0.2;
    }
    
    .copyright {
      text-align: center;
      font-family: "Medium Font";
      font-size: 14pt;
      opacity: 0.2;
    }
    @media screen and (max-width: 400px) {
      article {
        max-width: 600pt;
        margin: 0 auto;
        text-align: justify;
        text-justify: inter-word;
        padding-left: 3%;
        padding-right: 3%;
        .topHeading{
          text-align: center;
        }
        ul{
            list-style-type: square;
            
            font-family: "Medium Font";
        }
        h1 {
          font-size: 2.7rem;
          font-weight: bolder;
          font-family: "Medium Font";
        }
        h3{
          font-size: 1.7rem;
          font-weight: bolder;
          font-family: "Medium Font";
        }
      }
      
    }