@font-face {
  font-family: "Head Font";
  src: url("../fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: "Para Font";
  src: url("../fonts/BKANT.TTF");
}
.topMost {
  background: url("../images/Bg.JPG");
}
.resFirst {
  padding: 4.5rem 0;
  .sec1 {
    .title {
      font-family: "Head Font";
      text-align: center;
      h1 {
        font-size: 3.5rem;
        line-height: 1.2;
        @media (max-width: 992px) {
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .content {
      max-width: 960px;
      margin: auto;
      font-family: "Para Font";
      text-align: center;
      p {
        font-size: 1.5rem;
        @media (max-width: 768px) {
            font-size: 1.125rem;
          }
      }
    }
  }
}
.resSecond {
  background: white;

  .articles {
    padding: 4.5rem 0;
    .articleImg {
      text-align: center;
      height: 400px;
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .articleContent {
      .heading {
        font-family: "Para Font";
        h1 {
          font-size: 2rem;
          font-weight: bolder;
          line-height: 1.2;
        }
      }
      .body {
        font-family: "Para Font";
        text-align: justify;
        text-justify: inter-word;
        p {
          font-size: 1.5rem;
        }
        .readMore {
          height: 50px;
          width: 150px;
          font-family: "Para Font";
          color: white;
          font-size: 1rem;
          font-weight: bolder;
          background: black;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
  .dark {
    background-color: #f7f7f2;
    color: black;
    h1 {
      color: black;
    }
    .readMore1 {
      height: 50px;
      width: 150px;
      font-family: "Para Font";
      color: white;
      font-size: 1rem;
      font-weight: bolder;
      background: black;
      border: 2px solid white;
      border-radius: 10px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .resFirst{

  }
  .resSecond
  {
    .articles
    {
      padding: 2.5rem 0;
      .container{
        
        .articleImg{
          position: relative;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          margin:auto;
          height: 300px;
          width: 300px;
          img{
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .resFirst{
    .container{
      .sec1{
        .content{
          p{
            font-size: 1.125rem;
          }
        }
      }
    }
  }
  .resSecond
  {
    .articles
    {
      padding: 1rem 0rem;
      .container{
        
        .articleImg{
          position: relative;
          top: 0%;
          -ms-transform: translateY(0);
          transform: translateY(0);
          margin:auto;
          height: 300px;
          width: 300px;
          img{
            height: 100%;
            width: 100%;
          }
        }
        .articleContent{
          .heading{
            h1{
              font-size: 1.75rem;
            }
          }
          .body{
            p{
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  
}