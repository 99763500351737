// display properties
@font-face {
  font-family: "Para Font";
  src: url("../fonts/BKANT.TTF");
}
.d {
  &-none {
    display: none !important;
  }
  &-inline {
    display: inline !important;
  }
  &-inline-block {
    display: inline-block !important;
  }
  &-block {
    display: block !important;
  }
  &-table {
    display: table !important;
  }
  &-table-row {
    display: table-row !important;
  }
  &-table-cell {
    display: table-cell !important;
  }
  &-flex {
    display: flex !important;
  }
  &-inline-flex {
    display: inline-flex !important;
  }
}

// flex properties
.flex {
  &-row {
    flex-direction: row !important;
  }
  &-column {
    flex-direction: column !important;
  }
  &-row-reverse {
    flex-direction: row-reverse !important;
  }
  &-column-reverse {
    flex-direction: column-reverse !important;
  }

  &-wrap {
    flex-wrap: wrap !important;
  }
  &-nowrap {
    flex-wrap: nowrap !important;
  }
  &-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-between {
    justify-content: space-between !important;
  }
  &-around {
    justify-content: space-around !important;
  }
}

.align-items {
  &-start {
    align-items: flex-start !important;
  }
  &-end {
    align-items: flex-end !important;
  }
  &-center {
    align-items: center !important;
  }
  &-baseline {
    align-items: baseline !important;
  }
  &-stretch {
    align-items: stretch !important;
  }
}

.align-content {
  &-start {
    align-content: flex-start !important;
  }
  &-end {
    align-content: flex-end !important;
  }
  &-center {
    align-content: center !important;
  }
  &-between {
    align-content: space-between !important;
  }
  &-around {
    align-content: space-around !important;
  }
  &-stretch {
    align-content: stretch !important;
  }
}

.align-self {
  &-auto {
    align-self: auto !important;
  }
  &-start {
    align-self: flex-start !important;
  }
  &-end {
    align-self: flex-end !important;
  }
  &-center {
    align-self: center !important;
  }
  &-baseline {
    align-self: baseline !important;
  }
  &-stretch {
    align-self: stretch !important;
  }
}

//container
.container {
  &-1320 {
    max-width: 1320px;
    margin: auto;
  }
  &-1140 {
    max-width: 1140px;
    margin: auto;
  }
  &-960 {
    max-width: 960px;
    margin: auto;
  }
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 572px;
  }
  @media (min-width: 768px) {
    max-width: 752px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1172px;
  }
}

//navbar style
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins&display=swap");
//heading -> playfair
//content -> book antiqua
@font-face {
  font-family: "Font Name";
  src: url("../fonts/BKANT.TTF");
}
.navbar {
  font-family: "Font Name";
  font-weight: bolder;
  color: white;
}
.demoButton {
  font-family: "Font Name";
}
.demoButton:hover {
  background-color: #001529;
  color: white;
  font-weight: bolder;
}
.logoMen {
  padding-right: "50%";
}
.logoTitle {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
}
.modified-item:hover {
  border-bottom: 2px solid transparent !important;
  color: inherit !important;
}
.modified-item.ant-menu-submenu-active {
  border-bottom: 2px solid transparent !important;
  color: inherit !important;
}
.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}
.getADemo1 {
  height: 50px;
  width: 100px;
  background: transparent;
  border: 2px solid black;
  font-size: 1rem;
  font-weight: bolder;
  border-radius: 10px;
}
.horizontal-nav-right {
}
@media screen and (max-width: 1160px) {
  .navbar {
    padding: 0;
    max-width: 100%;
  }
  .logoMen {
    padding: 0;
  }
}
.disabledCursor {
  cursor: default;
}

.logo {
  float: left;
  width: 250px;
  height: 31px;
  color: black;
  font-size: 1.5rem;
  font-weight: bolder;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.site-layout-content {
  min-height: 280px;
  background: #fff;
}
.logoPic1 {
  height: 35px;
  width: 35px;
  filter: invert(100%);
}
// body{
//   margin
.aboutUsPic {
  height: 350px;
  width: 350px;
}
@media screen and (max-width) {
  .aboutUsPic {
    height: 250px;
    width: 250px;
  }
}
