@font-face {
  font-family: "Head Font";
  src: url("../fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: "Para Font";
  src: url("../fonts/BKANT.TTF");
}
$black: #000;
$white: #fff;
$greyLight: #e1e3e5;
$greyMedium: #8a959e;
$greyDark: #323a45;
$purple: #9b59b6;
$orange: #ff6d3a;
$blue: #3498db;
$defaultTransition: all 250ms ease-in-out;

//F3F5F9
.onProduct {
  background: url("../images/Bg.JPG");
  .firstSection {
    color: black;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 7%;
    padding-bottom: 7%;
    .writtenPart {
      .title {
        font-family: "Head Font";
        h1 {
          font-size: 3rem;
          font-weight: bolder;
          @media (max-width: 992px) {
            font-size: 2.5rem;
          }
          @media (max-width: 768px) {
            font-size: 2rem;
          }
        }
      }
      .content {
        font-family: "Para Font";
        p {
          font-size: 1.5rem;
        }
      }
    }
    .sideGif {
      height: 400px;
      width: 400px;
    }
  }
}

.option {
  background-color: black;
  padding: 4.5rem 0;
  .title {
    text-align: center;
    h1 {
      color: white;
      font-size: 3rem;
      font-weight: bolder;
      @media (max-width: 992px) {
        font-size: 2.5rem;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
  .content {
    text-align: center;
    color: white;
    p {
      font-size: 1.25rem;
    }
    .demo {
      width: 150px;
      height: 50px;
      background-color: transparent;
      font-size: 1.2rem;
      font-weight: bolder;
      color: white;
      border: 2px solid white;
      border-radius: 10px;
    }
  }
}
.howWorks {
  background: url('../images/bg1.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .working {
    padding: 1rem 0rem;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 3rem;
        font-weight: bolder;
        @media (max-width: 992px) {
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .content {
      .input{
        .topic{
          text-align: center;
          h3{
            font-size: 1.35rem;
          }
        }
        .desc{
          text-align: center;
          padding-top: 20%;
          .imageCont{
            height: 200px;
            width: 350px;
            .parent{
              img{
                height: 100%;
                width: 100%;
              }
              position: relative;
              top: 0;
              left: 50px;
              .image0{
                position: relative;
                top: 0;
                left: 0;
              }
              .image1{
                position: relative;
                top: 10px;
                left: -300px;
              }
              .image2{
                position: absolute;
                top: 0px;
                left: 30px;
              }
            }
          }
        }
      }
      .processing{
        .topic{
          text-align: center;
          h3{
            font-size: 1.35rem;
          }
        }
        .desc{
          .container {
            margin: 50px auto;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            background-image: url('../images/logo.png');
            background-size: contain;
            background-position: 20%;
            position: relative;    
        }
        
        .center-div {
           position: absolute;
           top: 45%;
           margin-top: -30px;
           width: 80%;
        }
        ul, li{
          list-style-type: "\21AA";
        }
        
        .center-div h2 {
            font-weight: bold;
            font-size: 1.125rem;
            color: #fff;
            text-align: center;
            margin-top: 100px;
        }
        
        .center-div p {
            margin-top: 30px;
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            color: #ccc;
            line-height: 1.8;
        
        }
        
        .choices {
            display: flex;
        }
        
        .left-top,
        .right-top,
        .right-bottom,
        .left-bottom {
            position: absolute;
            display: flex;
            transition: .6s;
            cursor: pointer;
            width: 100%;
            user-select: none;
        }
        
        .left-top {
            left: -45%;
            top:  0%;
        }
        
        .right-top {
            left: 85%;
            top: 0%;
        }
        
        .right-bottom {
            left: 85%;
            top: 80%;
        }
        
        .left-bottom {
            left: -45%;
            top: 80%;
        }
        
        .left-top-circle,
        .right-top-circle,
        .right-bottom-circle,
        .left-bottom-circle {
            position: relative;   
            background-color: #fff;
            width: 100px;
            height: 100px;
            border-radius: 50%;
           
        }
        
        .left-top-circle{
            left: 81%;    
        }
        
        
        .left-bottom-circle {
            left: 81%;
        }
        
        .left-top-circle img,
        .right-top-circle img,
        .right-bottom-circle img,
        .left-bottom-circle img {
            width: 70%;
            display: block;
            position: absolute;
            top: 15%;
            left: 12%;
        }
        
        .left-top-text,
        .right-top-text,
        .right-bottom-text,
        .left-bottom-text {
            position: relative;
            padding: 8px;
            width: 80%;
           
        }
        
        .left-top-text {
            left: -27%;
        }
        
        .right-top-text {
            left: 7.5%;
        }
        
        .right-bottom-text {
            left: 7.5%;
            top: 80%;
        }
        
        .left-bottom-text {
            left: -27%;
        }
        
        .left-top-text h3,
        .right-top-text h3,
        .right-bottom-text h3,
        .left-bottom-text h3 {
            text-align: right;
            color: #263c41;
            opacity: .9;
            font-size: 22px;
        }
        
        .right-top-text h3,
        .right-bottom-text h3{
            text-align: left;
        }
        
        .left-top-text p,
        .right-top-text p,
        .right-bottom-text p,
        .left-bottom-text p {
            margin-top: 30px;
            color: #979dac;
            font-weight: bold;
            line-height: 1.4;
            text-align: justify;
            font-size: 16px;
        }
        }
      }
      .output{
        .topic{
          text-align: center;
          h3{
            font-size: 1.35rem;
          }
        }
        .desc{
          padding-top: 8%;
          margin: auto;
          width: 100%;
          .imageCont1{
            text-align: center;
            img{
              height: 200px;
              width: 250px;
            }
          }
        }
      }
    }
  }
}
.customerAnalytics {
  background-color: black;
  color: white;
  .customer1 {
    padding: 4.5rem 0;
    .title {
      text-align: left;
      font-family: "Para Font";
      color: white;
      h1 {
        color: white;
        font-size: 3rem;
        font-weight: bolder;
        @media (max-width: 992px) {
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .content {
      
      .text {
        h1 {
          font-size: 3rem;
          color: white;
          font-family: "Para Font";
        }
        p {
          padding-top: 1.75rem;
          font-size: 1.75rem;
          font-family: "Para Font ";
        }
      }
      .imageParent{
        .image {
          text-align: center;
          height: 320px;
          width: 320px;
          margin: auto;
          .customerPic {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
.numbers1 {
  background-color: #f3f5f9;
  padding: 4.5rem 0rem;
  .number {
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    .icon {
      .iconPic {
        font-size: 6rem;
      }
    }
    .desc {
      padding-top: 5%;

      p {
        font-size: 1.5rem;
        &.number {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
.trial {
  padding: 4.5rem 0;
  .heading {
    text-align: center;
    font-family: "Para Font";
    h1 {
      font-size: 3rem;
      font-weight: bolder;
      @media (max-width: 992px) {
        font-size: 2.5rem;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
  .inAndOut {
    font-size: 1.2rem;
    .input {
      h1 {
        font-size: 2rem;
      }
      label {
        font-size: 1rem;
        font-weight: bold;
      }
      .diarization {
        width: 420px;
      }
      .speakers {
        width: 420px;
      }
      .lang{
        width: 420px;
      }
      .dragBox{
        width: 420px;
      }
      .radioInputs {
        border: 2px solid black;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        width: 190px;
      }
      
      .demo {
        background-color: black;
        color: white;
        width: 250px;
        font-size: 1rem;
        font-weight: bolder;
        height: 50px;
        border: none;
        border-radius: 10px;
      }
      Select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
      }
      .ant-form-text {
        font-size: 1.2rem;
      }
      .ant-select-suffix {
        font-size: 1.2rem;
      }
      .demo1 {
        width: 120px;
        height: 50px;
        color: white;
        background-color: #001529;
        font-weight: bolder;
        font-size: 1.2rem;
        border-radius: 10px;
        border: none;
      }
    }
    .output {
      text-align: center;
      h1 {
        font-size: 2rem;
      }
      .outputArea {
        border: 4px solid black;
        border-radius: 10px;
        overflow: hidden;
        outline: none;
        min-height: 550px;
        max-height: 550px;
        height: auto;
        resize: none;
        width: 50%;
        min-width: 10px;
        width: 500px;
        font-size: 1.5rem;
      }
    }
    .demo2 {
      height: 50px;
      background-color: black;
      color: white;
      font-size: 1.2rem;
      font-weight: bolder;
      border-radius: 10px;
      border: none;
    }
  }
}
.options {
  background: #f3f5f9;
  padding: 4.5rem 0;
  .title {
    text-align: center;
    font-family: "Para Font";
    h1 {
      font-size: 3rem;
      font-weight: bolder;
      @media (max-width: 992px) {
        font-size: 2.5rem;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
  .smallScreen {
    display: none;
  }
  .cards {
    @media (min-width: 900px) {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #e6e8e6;
      color: black;
      height: 380px;
      text-align: left;
      text-decoration: none;
      border-top: 5px solid #1f363d;
      border-right: 1px solid $greyLight;
      border-bottom: 1px solid $greyLight;
      border-left: 1px solid $greyLight;
      padding: 1.75rem;
      transition: $defaultTransition;
      width: auto;
      border-radius: 0 0 6px 6px;
      @media (min-width: 900px) {
      }
      &:hover,
      &:focus {
        background: #1f363d;
        border-color: #1f363d;
        color: $white;
        outline: 0;
        .cards__date {
          color: rgba($white, 0.6);
        }
        .cards__title {
          color: $white;
        }
        .cards__more {
          color: $white;
          border-bottom: solid 1px rgba($white, 0.6);
        }
      }
      &:nth-of-type(2) {
        border-top: 5px solid #40798c;
        &:hover,
        &:focus {
          background: #40798c;
          border-color: #40798c;
        }
      }
      &:nth-of-type(3) {
        border-top: 5px solid $blue;
        &:hover,
        &:focus {
          background: $blue;
          border-color: $blue;
        }
      }
      p {
        font-size: 1.25rem;
      }
    }

    &__date {
      display: block;
      color: $greyMedium;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 2.4rem;
      font-weight: 700;
      transition: $defaultTransition;
    }

    &__title {
      color: black;
      font-weight: 300;
      margin-bottom: 2rem;
      transition: $defaultTransition;
      font-size: 2rem;
      line-height: 3rem;
      font-family: "Para Font";
      @media (min-width: 1200px) {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    }

    &__more {
      margin-top: auto;
      color: $greyMedium;
      display: inline-block;
      border-bottom: 1px solid $greyLight;
      font-size: 1.5rem;
      line-height: 1.6rem;
      font-weight: 400;
      transition: $defaultTransition;
    }
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }
  .howWorks {
    .working {
      padding: 2rem;
      .content{
        .input{
          .topic{
            text-align: center;
            h3{
              font-size: 1.35rem;
            }
          }
          .desc{
            text-align: center;
            padding-top: 2%;
            .imageCont{
              height: 150px;
              width: 250px;
              width: 50%;
              margin: auto;
              .parent{
                img{
                  height: 100%;
                  width: 100%;
                }
                position: relative;
                top: 0;
                left: 0px;
                .image0{
                  position: relative;
                  top: 0;
                  left: 0;
                }
                .image1{
                  position: relative;
                  top: 10px;
                  left: -400px;
                }
                .image2{
                  position: absolute;
                  top: 0px;
                  left: 30px;
                }
              }
            }
          }
        }
        .output{
          .topic{
            text-align: center;
            h3{
              font-size: 1.35rem;
            }
          }
          .desc{
            margin: auto;
            width: 100%;
            text-align: center;
            .imageCont1{
              text-align: center;
              img{
                height: 400px;
                width: 400px;
              }
            }
          }
        }
      }
    }
  }

  .onProduct {
    background-color: #fff;
    .firstSection {
      color: black;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 7%;
      padding-bottom: 7%;
      .writtenPart {
        .title {
          font-family: "Head Font";
          h1 {
            font-size: 2rem;
          }
        }
        .content {
          font-family: "Para Font";
          p {
            font-size: 1.125rem;
          }
        }
      }
      .sideGif {
        height: 200px;
        width: 200px;
      }
    }
  }
  .numbers1 {
    background-color: #f3f5f9;
    padding: 1.5rem 0rem;
    .number {
      padding-top: 5%;
      padding-bottom: 5%;
      text-align: center;
      .icon {
        .iconPic {
          font-size: 3rem;
        }
      }
      .desc {
        p {
          font-size: 1.25rem;
          padding: 0;
        }
      }
    }
  }
  .trial {
    padding: 4.5rem 0;
    .heading {
      text-align: center;
      font-family: "Para Font";
    }
    .inAndOut {
      font-size: 1.2rem;
      .input {
        text-align: left;
        h1 {
          font-size: 1.5rem;
        }
        label {
          font-size: 1rem;
        }
        .dragger {
          height: 100px;
          p {
            padding: 0;
            line-height: 3px;
          }
        }
        .ant-upload.ant-upload-drag{
          width: 100%;
        }
        .ant-select-suffix{
          height: 500px;
        }
        .diarization {
          width: 520px;
        }
        .speakers {
          width: 420px;
        }
        .demo {
          background-color: black;
          color: white;
          width: 250px;
          font-size: 1rem;
          font-weight: bolder;
          height: 40px;
          border: none;
          border-radius: 10px;
        }
        Select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: "";
        }
        .ant-form-item-label {
          text-align: center;
        }
        .ant-form-text {
          font-size: 1.2rem;
        }
        .ant-select-suffix {
          font-size: 1.2rem;
        }
        .demo1 {
          width: 120px;
          height: 40px;
          color: white;
          background-color: #001529;
          font-weight: bolder;
          font-size: 1rem;
          border-radius: 10px;
          border: none;
        }
      }
      .output {
        text-align: center;
        h1 {
          font-size: 1.5rem;
        }
        .outputArea {
          border: 4px solid black;
          border-radius: 10px;
          overflow: hidden;
          outline: none;
          resize: none;
          width: 50%;
          min-width: 10px;
          width: 250px;
          font-size: 1.5rem;
          height: 500px;
        }
      }
      .demo2 {
        height: 40px;
        background-color: black;
        color: white;
        font-size: 1rem;
        font-weight: bolder;
        border-radius: 10px;
        border: none;
      }
    }
  }
  .customerAnalytics {
    background-color: black;
    color: white;
    .customer1 {
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 5%;
      padding-right: 5%;
      .title {
        text-align: left;
        font-family: "Para Font";
        color: white;
        h1 {
          font-size: 2rem;
          color: white;
        }
      }
      .content {
        
        .text {
          padding-top: 12px;
          text-align: left;
          h1{
            color: white;
            font-size: 2rem;
          }
          p {
            font-size: 1.15rem;
            font-family: "Para Font ";
          }
        }
        .imageParent{
          .image {
            text-align: left;
            height: 220px;
            width: 220px;
            margin: auto;
            .customerPic {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .trial .inAndOut .input {
    text-align: left;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }
  .onProduct {
    background-color: #fff;
    .firstSection {
      color: black;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 7%;
      padding-bottom: 7%;
      .writtenPart {
        .title {
          font-family: "Head Font";
          h1 {
            font-size: 2rem;
          }
        }
        .content {
          font-family: "Para Font";
          p {
            font-size: 1.125rem;
          }
        }
      }
      .sideGif {
        height: 200px;
        width: 200px;
      }
    }
  }
  .numbers1 {
    background-color: #f3f5f9;
    .number {
      padding-top: 5%;
      padding-bottom: 5%;
      text-align: center;
      .icon {
      }
      .desc {
        p {
        }
      }
    }
  }
  .trial {
    padding: 4.5rem 0;
    .heading {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 1.75rem;
      }
    }
    .inAndOut {
      font-size: 1.2rem;
      label {
        font-weight: 500;
      }
      .input {
        text-align: center;
        .diarization {
          width: auto;
        }
        .speakers {
          width: auto;
        }
        .lang{
          width: auto;
        }
        .dragBox{
          width: auto;
          .dragger{
            height: 100px;
          }
        }
        .btnPart{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        h1 {
          font-size: 1.5rem;
        }
        label {
          font-size: 1rem;
        }
        .dragger {
          height: 70px;
          p {
            padding: 0;
            line-height: 3px;
          }
        }
        .demo {
          background-color: black;
          color: white;
          width: 250px;
          font-size: 1rem;
          font-weight: bolder;
          height: 40px;
          border: none;
          border-radius: 10px;
        }
        
        Select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: "";
        }
        .ant-form-item-label {
          text-align: center;
        }
        .ant-form-text {
          font-size: 1.2rem;
        }
        .ant-select-suffix {
          font-size: 1.2rem;
        }
        .demo1 {
          width: 120px;
          height: 40px;
          color: white;
          background-color: #001529;
          font-weight: bolder;
          font-size: 1rem;
          border-radius: 10px;
          border: none;
        }
      }
      .output {
        text-align: center;
        h1 {
          font-size: 1.5rem;
        }
        .outputArea {
          border: 4px solid black;
          border-radius: 10px;
          overflow: hidden;
          outline: none;
          min-height: 500px;
          max-height: 500px;
          height: auto;
          resize: none;
          width: 50%;
          min-width: 10px;
          width: 300px;
          font-size: 1.5rem;
        }
      }
      .demo2 {
        height: 40px;
        background-color: black;
        color: white;
        font-size: 1rem;
        font-weight: bolder;
        border-radius: 10px;
        border: none;
      }
    }
  }
  .option {
    background-color: black;
    padding-top: 5%;
    padding-bottom: 5%;
    .title {
      text-align: center;
      h1 {
        font-size: 1.75rem;
        font-family: "Para Font";
        color: white;
      }
    }
    .content {
      text-align: center;
      color: white;
      p {
        font-size: 1.25rem;
      }
      .demo {
        width: 150px;
        height: 40px;
        background-color: transparent;
        font-size: 1rem;
        font-weight: bolder;
        color: white;
        border: 2px solid white;
        border-radius: 10px;
      }
    }
  }
  .customerAnalytics {
    background-color: black;
    color: white;
    .customer1 {
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 0%;
      padding-right: 0%;
      .title {
        text-align: left;
        font-family: "Para Font";
        color: white;
        h1 {
          font-size: 1.75rem;
          color: white;
        }
      }
      .content {
        .text {
          p {
            font-size: 1rem;
            font-family: "Para Font ";
            padding: 0;
          }
        }
        .image {
          text-align: center;
          height: 200px;
          width: 200px;

          .customerPic {
            height: 100%;
            width: 100;
          }
        }
      }
    }
  }
  .options {
    background: #f3f5f9;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 1.75rem;
      }
    }
    .cards {
      padding: 20px;
      max-width: 100vw;
      @media (min-width: 900px) {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
      }
      .optBreak {
        display: none;
      }

      &__item {
        flex: 0 1 auto;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #e6e8e6;
        color: black;
        height: 300px;
        text-align: left;
        text-decoration: none;
        border-top: 5px solid #1f363d;
        border-right: 1px solid $greyLight;
        border-bottom: 1px solid $greyLight;
        border-left: 1px solid $greyLight;
        padding: 10px 10px;
        transition: $defaultTransition;
        width: auto;

        &:hover,
        &:focus {
          background: #1f363d;
          border-color: #1f363d;
          color: $white;
          outline: 0;
          .cards__date {
            color: rgba($white, 0.6);
          }
          .cards__title {
            color: $white;
          }
          .cards__more {
            color: $white;
            border-bottom: solid 1px rgba($white, 0.6);
          }
        }
        &:nth-of-type(2) {
          border-top: 5px solid #40798c;
          &:hover,
          &:focus {
            background: #40798c;
            border-color: #40798c;
          }
        }
        &:nth-of-type(3) {
          border-top: 5px solid $blue;
          &:hover,
          &:focus {
            background: $blue;
            border-color: $blue;
          }
        }
      }

      &__date {
        display: block;
        color: $greyMedium;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 2.4rem;
        font-weight: 700;
        transition: $defaultTransition;
      }

      &__title {
        color: black;
        font-weight: 300;
        margin: 0 0 30px 0;
        transition: $defaultTransition;
        font-size: 1.5rem;
        line-height: 3rem;
        font-family: "Para Font";
        @media (min-width: 1200px) {
          font-size: 2.4rem;
          line-height: 3.5rem;
        }
      }

      &__more {
        margin-top: auto;
        color: $greyMedium;
        display: inline-block;
        border-bottom: 1px solid $greyLight;
        font-size: 1.5rem;
        line-height: 1.6rem;
        font-weight: 400;
        transition: $defaultTransition;
      }
    }
    .secondRow {
      margin: auto;
      width: 50%;
    }
  }
  .trial .inAndOut .input {
    text-align: left;
  }
  .trial .inAndOut .input .ant-form-item-label {
    text-align: left;
  }
  .trial .inAndOut .input .radioInputs {
    border: 2px solid black;
    padding-top: 5px;
    padding-bottom:5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 150px;
  }
  .ant-form-item {
    margin-bottom: 24px;
  }
  .trial .inAndOut .input .diarization {
    width: 100%;
  }
  
}
@media screen and (max-width: 767px) {
  .option {
    .container {
      .title {
        h1 {
          font-size: 1.75rem;
        }
      }
      .content {
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .howWorks {
      .working {
        .title {
          h1 {
            font-size: 1.75rem;
          }
        }
        .content
        {
          .input{
            .desc{
              text-align: center;
              padding-top: 2%;
              .imageCont{
                height: 120px;
                width: 100%;
                margin: auto;
                .parent{
                  img{
                    height: 100%;
                    width: 100%;
                  }
                  position: relative;
                  top: 0;
                  left: 0px;
                  .image0{
                    position: relative;
                    top: -20px;
                    left: 0;
                  }
                  .image1{
                    position: relative;
                    top: 40px;
                    left: 2px;
                  }
                  .image2{
                    position: absolute;
                    top: 0px;
                    left: 30px;
                  }
                }
              }
            }
          }
          .processing{
            .topic{
              text-align: center;
              h3{
                font-size: 1.35rem;
              }
            }
            .desc{
              .container {
                margin: 50px auto;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-image: url('../images/logo.jpg');
                background-size: cover;
                background-position: 43%;
                position: relative;    
            }
            ul{
              display: none;
            }
            
            
            .left-top {
                left: -55%;
                top:  -20%;
            }
            
            .right-top {
                left: 85%;
                top: -20%;
            }
            
            .right-bottom {
                left: 85%;
                top: 80%;
            }
            
            .left-bottom {
                left: -55%;
                top: 80%;
            }
            }
          }
          .output{
            .desc{
              .imageCont1{
                text-align: center;
                img{
                  width: 200px;
                  height: 200px;
                }
              }
            }
          }
        }
      }
  }
  .options {
    background: #f3f5f9;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    .title {
      text-align: center;
      font-family: "Para Font";
      h1 {
        font-size: 1.75rem;
      }
    }
    .cards {
      &__item {
        height: 340px;
        width: auto;
      }

      &__date {
        display: block;
        color: $greyMedium;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 2.4rem;
        font-weight: 700;
        transition: $defaultTransition;
      }

      &__title {
        color: black;
        font-weight: 300;
        margin: 0 0 30px 0;
        transition: $defaultTransition;
        font-size: 1.5rem;
        line-height: 3rem;
        font-family: "Para Font";
        .optBreak {
          display: none;
        }
        @media (min-width: 1200px) {
          font-size: 2.4rem;
          line-height: 3.5rem;
        }
      }

      &__more {
        margin-top: auto;
        color: $greyMedium;
        display: inline-block;
        border-bottom: 1px solid $greyLight;
        font-size: 1.65rem;
        line-height: 1.6rem;
        font-weight: 400;
        transition: $defaultTransition;
      }
    }
    .secondRow {
      margin: 0;
      width: 100%;
    }
  }
  .trial .inAndOut .input .ant-form-item-label {
    text-align: left;
  }
  
  .trial .inAndOut .input .radioInputs {
    border: 2px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 155px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .trial .inAndOut .input .diarization {
    width: 100%;
  }
  .trial .inAndOut .output .outputArea{
    min-height: 300px;
  }
}
