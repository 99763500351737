.horizontal-nav {
  &-container {
    padding: 16px 20px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-right {
    display: flex;
    align-items: center;
  }
  &-expanded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      display: none;
      
    }
  } 
  &-dropdown {
    @media (min-width: 767px) {
      display: none;
    }
  }
}
.logIn{
  left: 85%;
}
@media screen and (max-width: 1300px) {
  .logIn{
    left: 55%;
  }
  
}
@media screen and (max-width:1200px) {
  .getADemoLink{
    display: none;
  }
  
}
@media screen and (max-width: 540px) {
  .drpDown{
    padding-left: 750%
  }
}
@media screen and (max-width: 940px) {
  .logIn{
    left: 18%;
  }
  
}
@media screen and (max-width:1024px) {
  .getADemoLink{
    display: none;
  }
  .logIn{
    left: 40%;
  }
  .drpDown{
    padding-left: 350%
  }
  
}

@media screen and (max-width: 767px) {
  .getADemoLink{
    display: none;
  }
  
}
@media screen and (max-width: 768px) {
  .logIn{
    left: 20%;
  }
}
