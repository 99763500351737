@font-face {
  font-family: "para font";
  src: url("../fonts/BKANT.TTF");
}
@font-face {
  font-family: "head font";
  src: url("../fonts/AvenirLTStd-Black.otf");
}
body {
  margin: 0;
  padding: 0;
}
h1{
  font-size: 3rem;
  font-weight: bolder;
}
p{
  font-size: 1.5rem;
}
.ocean {
  height: 150px; /* change the height of the waves here */
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  background: #EBEBEB;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23001529'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.homePage1 {
  
}
.whyListenAI {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  .title {
    text-align: center;
    font-family: "Para Font";
  }
  .content {
    font-family: "para Font";
    .points {
      ul {
        li {
          list-style-type: none;
          padding-left: 4%;
          padding-right: 4%;
          .point {
            .bold {
              h1 {
                font-size: 2rem;
                font-family: "para font";
              }
            }
          }
        }
      }
    }
  }
}
.whyUs1 {
    padding: 64px 0px;
  .reasons {
    .title {
      text-align: center;
      font-family: "Para Font";
    }
    .main-container {
    //   padding: 30px;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .card2 {
      margin: 50px;
      padding: 20px;
      width: 500px;
      min-height: 360px;
      display: grid;
      border-radius: 10px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
      transition: all 0.2s;
    }

    .card2:hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.01);
    }

    .card__link,
    .card__exit,
    .card__icon {
      position: relative;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
    }

    .card__link::after {
      position: absolute;
      left: 0;
      content: "";
      width: 0%;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.5s;
    }

    .card__link:hover::after {
      width: 100%;
    }

    .card__exit {
      grid-row: 1/2;
      justify-self: end;
    }

    .card__icon {
      grid-row: 2/3;
      font-size: 30px;
    }

    .card__title {
      grid-row: 3/4;
      font-size: 2rem;
      font-family: "Para Font";
      font-weight: 400;
      color: #ffffff;
    }

    .card__apply {
      grid-row: 4/5;
      align-self: center;
    }

    /* CARD BACKGROUNDS */

    .card-1 {
      background: #aeb8c1;
    }

    .card-2 {
      background: #ce8565;
    }

    .card-3 {
      background: #384d62;
    }

    .card-4 {
      background: #8a96a2;
    }

    /* RESPONSIVE */

    @media (max-width: 1600px) {
      .cards {
        justify-content: center;
      }
    }
    .theory {
      color: white;
    }
  }
}
.homePage {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../images/bacgroundLight.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .title {
    padding-top: 22%;
    padding-left: 15%;
    padding-right: 7%;
    text-align: left;
    h1 {
      font-family: "head font";
      font-size: 3rem;
      line-height: 1.2;
      font-weight: bolder;
      color: black;
    }
  }
  .content {
    padding-left: 15%;
    padding-right: 7%;
    p {
      font-family: "para font";
      font-size: 1.5rem;
      color: black;
    }
  }
  .optional {
    display: none;
  }
}
.statistics {
  background: #292526;
  .numbers {
    padding: 25px 0px;
    text-align: center;
    .title {
      h1 {
        font-family: "para font";
        font-size: 3rem;
        font-weight: bolder;
        color: white;
      }
    }
    .spanCont{
      font-size: 3.5rem;
      font-weight: bolder;
    }
    .content {
      padding-top: 2%;
      padding-bottom: 2%;
      p {
        font-family: "para font";
        font-size: 1.5rem;
        color: white;
      }
      .icon {
        color: white;
        font-size: 5rem;
        .iconPic {
          height: 50px;
          font-size: 5rem;
        }
      }
    }
  }
}

.getADemo {
  height: 50px;
  width: 200px;
  font-weight: bolder;
  font-size: 1.2rem;
  font-family: "para font";
  color: black;
  border: 2px solid black;
  background: transparent;
}
.parent {
  display: flex;
}
@media (max-width: 767px) {
  .parent {
    flex-direction: column-reverse;
  }
}
.features {
  padding: 64px 20px;
  .LeftImg {
    float: right;
    height: 350px;
    width: 350px;
    .sideImg {
      height: 100%;
      width: 100%;
    }
  }
  .RightImg {
    height: 350px;
    width: 350px;
    text-align: center;
    .sideImg {
      height: 100%;
      width: 100%;
    }
  }
  .RightText {
    .feature {
      .title {
        font-family: "para font";
        h1 {
          font-size: 2.5rem;
        }
      }
      .content {
        text-align: justify;
        text-justify: inter-word;
        font-family: "para font";
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
  .leftText {
    .feature {
      .title {
        font-family: "para font";
        h1 {
          font-size: 2.5rem;
        }
      }
      .content {
        text-align: justify;
        text-justify: inter-word;
        font-family: "para font";
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.reviews {
  .whyUs {
    .bigSpace {
      padding-top: 30%;
      padding-bottom: 30%;
      padding-left: 10%;
      padding-right: 10%;
      text-align: center;
      .title {
        font-family: "para font";
      }
    }
    .users {
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 2%;
      padding-right: 2%;
      .userCard {
        text-align: center;
        .comment {
          p {
            font-size: 1.2rem;
            font-weight: bolder;
          }
        }
        .photo {
          img {
            height: 120px;
            width: 120px;
            border-radius: 50%;
          }
        }
        .user {
          padding-top: 1%;
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
.slide {
  background: #001529;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  .title {
    h1 {
      color: white;
      font-family: "para font";
    }
  }
  .slide {
    padding-top: 10%;
    padding-bottom: 10%;
    text-align: center;
    h1 {
      color: white;
    }
  }
}
.tryOut {
  padding: 5rem 0;
  text-align: center;
  .trySection {
    h1 {
      font-size: 3rem;
      font-weight: bolder;
      font-family: "para font";
    }
    h2 {
      font-size: 1.5rem;
      font-weight: bolder;
      font-family: "para font";
    }
    .demo1 {
      width: 200px;
      height: 50px;
      border: 2px solid #001529;
      background: #001529;
      color: white;
      border-radius: 10px;
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }
}

@media screen and (max-width: 1024px) {
  .homePage {
    padding-bottom: 5%;
    height: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../images/bacgroundLight.jpg");
    background-repeat: no-repeat;
    height: 68vh;
    .title {
      padding-top: 100px;
      padding-left: 10%;
      padding-right: 7%;
      text-align: left;
      h1 {
        font-family: "head font";
        font-size: 2rem;
        font-weight: bolder;
        color: black;
      }
    }
    .content {
      padding-left: 10%;
      padding-right: 7%;
      p {
        font-family: "para font";
        font-size: 1.125rem;
        color: black;
      }
    }
    .optional {
      display: none
    }
  }
  .statistics {
    .numbers {
      .title {
        h1 {
          font-size: 1.75rem;
        }
      }
      .spanCont{
        font-size: 3rem;
        font-weight: bolder;
      }
      .content {
        .icon {
          .iconPic {
            font-size: 3.5rem;
          }
        }
        .point {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .features {
    padding: 10px 20px;
    .LeftImg {
      float: none;
      text-align: center;
      width: 200px;
      height: 200px;
      margin: auto;
      .sideImg {
        height: 100%;
        width: 100%;
      }
    }
    .RightImg {
      height: 200px;
      width: 200px;
      margin: auto;
      .sideImg {
        height: 100%;
        width: 100%;
      }
    }
    .RightText {
      padding-right: 0%;
      padding-left: 0%;
      padding: 20px;
      .feature {
        .title {
          font-family: "para font";
          h1 {
            font-size: 1.75rem;
          }
        }
        .content {
          font-family: "para font";
          p {
            font-size: 1rem;
          }
        }
      }
    }
    .leftText {
      .feature {
        padding: 1.5rem 0rem;
        .title {
          font-family: "para font";
          h1 {
            font-size: 1.75rem;
          }
        }
        .content {
          font-family: "para font";
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .slideShow {
    .articles {
      padding-top: 5px;
      .title {
        h1 {
          font-size: 1.75rem;
        }
      }
      .slide {
        padding-top: 15px;
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
  .whyUs1 {
    padding: 10px 20px;
  .reasons {
    .title {
      text-align: center;
      font-family: "Para Font";
    }
    .main-container {
    //   padding: 30px;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .card2 {
      margin: 20px;
      padding: 20px;
      width: 300px;
      height: 250px;
      display: grid;
      border-radius: 10px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
      transition: all 0.2s;
    }

    .card2:hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.01);
    }

    .card__link,
    .card__exit,
    .card__icon {
      position: relative;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
    }

    .card__link::after {
      position: absolute;
      left: 0;
      content: "";
      width: 0%;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.5s;
    }

    .card__link:hover::after {
      width: 100%;
    }

    .card__exit {
      grid-row: 1/2;
      justify-self: end;
    }

    .card__icon {
      grid-row: 2/3;
      font-size: 30px;
    }

    .card__title {
      grid-row: 3/4;
      font-size: 1.5rem;
      font-family: "Para Font";
      font-weight: 400;
      color: #ffffff;
    }

    .card__apply {
      grid-row: 4/5;
      align-self: center;
    }

    /* CARD BACKGROUNDS */

    .card-1 {
      background: #aeb8c1;
    }

    .card-2 {
      background: #ce8565;
    }

    .card-3 {
      background: #384d62;
    }

    .card-4 {
      background: #8a96a2;
    }

    /* RESPONSIVE */

    @media (max-width: 1600px) {
      .cards {
        justify-content: center;
      }
    }
    .theory {
      color: white;
    }
  }
}
  .tryOut {
    padding: 2rem 0;
    .trySection {
      h1 {
        font-size: 1.75rem;
      }
      h2 {
        font-size: 1.5rem;
      }
      .demo1 {
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .ocean{
    display: none;
    .wave{
      display: none;
    }
  }
  .homePage {
    padding-bottom: 5%;
    height: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../images/Bg.JPG");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .title {
      padding-top: 5%;
      padding-left: 5%;
      text-align: left;
      h1 {
        font-family: "head font";
        font-size: 2rem;
        font-weight: bolder;
        color: black;
      }
    } 
    .content {
      padding-left: 10%;
      padding-left: 5%;
      p {
        font-family: "para font";
        font-size: 1.125rem;
        color: black;
      }
    }
    .optional {
      display: block;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 5%;
      padding-right: 5%;
      .laptop {
        margin: auto;
        height: 100%;
        width: 100%;
      }
    }
  }
  .whyUs1 {
    padding: 20px 10px;
  .reasons {
    .title {
      text-align: center;
      font-family: "Para Font";
    }
    .main-container {
    //   padding: 30px;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .card__title{
      h2{
        font-weight: bolder;
      }
    }

    .card2 {
      margin: 10px;
      padding: 10px;
      width:auto;
      height: 350px;
      display: grid;
      border-radius: 10px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
      transition: all 0.2s;
    }

    .card2:hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
      transform: scale(1.01);
    }

    .card__link,
    .card__exit,
    .card__icon {
      position: relative;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
    }

    .card__link::after {
      position: absolute;
      left: 0;
      content: "";
      width: 0%;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.5s;
    }

    .card__link:hover::after {
      width: 100%;
    }

    .card__exit {
      grid-row: 1/2;
      justify-self: end;
    }

    .card__icon {
      grid-row: 2/3;
      font-size: 30px;
    }

    .card__title {
      grid-row: 3/4;
      font-size: 1.5rem;
      font-family: "Para Font";
      font-weight: 400;
      color: #ffffff;
    }

    .card__apply {
      grid-row: 4/5;
      align-self: center;
    }

    /* CARD BACKGROUNDS */

    .card-1 {
      background: #aeb8c1;
    }

    .card-2 {
      background: #ce8565;
    }

    .card-3 {
      background: #384d62;
    }

    .card-4 {
      background: #8a96a2;
    }

    /* RESPONSIVE */

    @media (max-width: 1600px) {
      .cards {
        justify-content: center;
      }
    }
    .theory {
      color: white;
    }
  }
}
  .statistics {
    .numbers {
      padding: 20px 20px;
      .title {
        h1 {
          font-size: 1.65rem;
        }
      }
      .spanCont{
        padding-top: 5%;
        font-size: 2rem;
        font-weight: bolder;
      }
      .content {
        padding-top: 1%;
        padding-bottom: 1%;
        .icon {
          .iconPic {
            font-size: 3.5rem;
          }
        }
        .point {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .features {
    padding: 0 20px;
    .LeftImg {
      text-align: center;
      height: 200px;
      width: 200px;
      .sideImg {
        height: 100%;
        width: 100%;
      }
    }
    .RightImg {
      text-align: center;
      height: 200px;
      width: 200px;
      .sideImg {
        height: 100%;
        width: 100%;
      }
    }
    .RightText {
      padding-right: 0%;
      padding-left: 0%;
      .feature {
        .title {
          font-family: "para font";
          h1 {
            font-size: 1.75rem;
            line-height: 1.2;
          }
        }
        .content {
          font-family: "para font";
          p {
            font-size: 1rem;
          }
        }
      }
    }
    .leftText {
      .feature {
        .title {
          font-family: "para font";
          h1 {
            font-size: 1.75rem;
            line-height: 1.2;
          }
        }
        .content {
          font-family: "para font";
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .slideShow {
    .articles {
      .title {
        h1 {
          font-size: 1.75rem;
        }
      }
      .slide {
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
  .tryOut {
    .trySection {
      h1 {
        font-size: 1.75rem;
        line-height: 1.2;
      }
      h2 {
        font-size: 1.5rem;
      }
      .demo1 {
        font-size: 1rem;
      }
    }
  }
}
///wave
